const summary =
  "Led clients through competitive state cannabis business licensing competitions by designing detailed 3-5 year business plans with financial projections, compliant Standard Operating Procedures, security plans, marketing plans, community service plans, and architectural/electrical/plumbing diagrams.";

const accomplishments = [
  {
    heading: "Spearheaded effort to win a client 3 dispensary licenses in the Las Vegas area, valued at $30M.",
    detail: "",
  },
  {
    heading: "Developed Excel VBA programs to automate data collection, analysis, and reporting, enhancing accuracy.",
    detail: "",
  },
  {
    heading: "Revamped operational systems for clients struggling to get their licensed facilities operational, ensuring compliance, passing regulatory inspections, and enabling client to begin generating revenues.",
    detail: "",
  },
];

export { summary, accomplishments };
