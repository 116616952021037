import React from 'react';
import Heading from '../Heading/Heading';
import './TitleAndDate.css';


const TitleAndDate = ({ title, time, linkText, linkURL }) => {
  return (
    <div className="flex-row-between-end title-and-date">
      <Heading text={title} linkText={linkText} linkURL={linkURL} classes="" level={3} />
      <h4>{time}</h4>
    </div>
  );
};

export default TitleAndDate;