import React from 'react';
import './SubtitleAndLocation.css';
import Heading from '../Heading/Heading';

const SubtitleAndLocation = ({ subtitle, location }) => {
  return (
    <div className="flex-row-between-end subtitle-and-location">
      <Heading text={subtitle} classes="fira-sans-medium" level={4} />
      <h5 className="location fira-sans-medium-italic">{location}</h5>
    </div>
  );
}

export default SubtitleAndLocation;