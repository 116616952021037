import React from 'react';
import './SkillTable.css';

const SkillTable = ({ skills }) => {
  return (
    <table className="skill-table">
      <tbody>
        {skills.map((skill, index) => {
          const { label, text } = skill;
          return (
            <tr key={index}>
              <td className="label-cell">{label}</td>
              <td className="text-cell"><span className="text-content">{text}</span></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  );
};

export default SkillTable;
