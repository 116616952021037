import React from 'react';
import TitleAndDate from '../common/TitleAndDate/TitleAndDate';
import SubtitleAndLocation from '../common/SubtitleAndLocation/SubtitleAndLocation';
import Summary from '../common/Summary/Summary';
import './School.css';


const School = ({ title, time, linkText, linkURL, subtitle, location, summary }) => {
  return (
    <>
      <TitleAndDate title={title} time={time} linkText={linkText} linkURL={linkURL} />
      <SubtitleAndLocation subtitle={subtitle} location={location} />
      <Summary text={summary} />
    </>
  )
}

export default School;