import './App.css';
import LinksSection from './components/LinksSection/LinksSection';
import Section from './components/Section/Section';
import SkillTable from './components/SkillTable/SkillTable';
import Company from './components/Company/Company';
import School from './components/School/School';
import Project from './components/Project/Project';
import { summary as companyAirhouseSummary, accomplishments as companyAirhouseAccomplishments } from './data/companies/airhouse';
import companyEricSummary from './data/companies/eric';
import { summary as companyJWGSummary, accomplishments as companyJWGAccomplishments } from './data/companies/jwg';
import { summary as companyLVRSummary, accomplishments as companyLVRAccomplishments } from './data/companies/lvr';
import projectTJTAccomplishments from './data/projects/trade-journal-tracker';
import { summary as econCourses } from './data/education/unlv-econ';
import { summary as mathCourses } from './data/education/unlv-math';


function App() {
  return (
    <div className="App">
      <header>
        <h1 className="">Spencer Franklin</h1>
        <p className="highlight">
          <strong>
            Full-Stack Software Engineer
          </strong>
        </p>
        <LinksSection />
      </header>

      <div className="main-content">
        <Section name="Technology" />

        <SkillTable
          skills={[
            { label: "Languages", text: "Python [5 years] ∙ JavaScript (ES5/ES6) ∙ TypeScript ∙ Excel VBA" },
            { label: "Frameworks", text: "Django & Django Rest Framework (DRF) [3 years] ∙ React ∙ Flask" },
            { label: "Tools", text: "PostgreSQL ∙ AWS ∙ Docker ∙ CircleCi ∙ Sentry" }
          ]}
        />

        <Section name="Work & Entrepreneurship" />
        
        <Company
          title="Airhouse, Inc."
          time="Nov 2021 - Apr 2024"
          subtitle="Full-Stack Software Engineer"
          location="San Francisco, CA"
          summary={companyAirhouseSummary}
          accomplishments={companyAirhouseAccomplishments}
        />
        <Company
          title="The J.Whitney Group LLC"
          time="May 2016 – Jul 2020"
          subtitle="Co-founder and Chief Operations and Technology Officer"
          location="Las Vegas, NV"
          summary={companyJWGSummary}
          accomplishments={companyJWGAccomplishments}
        />
        <Company
          title="Las Vegas ReLeaf"
          time="Oct 2015 – Apr 2017"
          subtitle="Manager"
          location="Las Vegas, NV"
          summary={companyLVRSummary}
          accomplishments={companyLVRAccomplishments}
        />
        <Company
          title="Eric Kurtzman"
          time="Nov 2011 – Nov 2021"
          linkText="Stretto Profile Link"
          linkURL="https://www.stretto.com/our-experts/eric-kurtzman/"
          subtitle="Executive Assistant/Accountant"
          location="Las Vegas, NV"
          summary={companyEricSummary}
        />

        <Section name="Projects" />

        <Project
          subtitle="Trade Journal Tracker"
          time="Sep 2021 – Nov 2021"
          accomplishments={projectTJTAccomplishments}
        />

        <Section name="Education" />

        <School
          title="Bachelor of Arts in Mathematics"
          time="Aug 2010 – May 2015"
          subtitle="University of Nevada, Las Vegas"
          location="Las Vegas, NV"
          summary={mathCourses}
        />
        <School
          title="Bachelor of Arts in Economics"
          time="Aug 2010 – May 2015"
          subtitle="University of Nevada, Las Vegas"
          location="Las Vegas, NV"
          summary={econCourses}
        />

        <Section name="Interests" />

        <p className="fira-sans-light margin-top-regular">
          Meditation: 300+ hours in 2023 | Consistently practicing since 2015.
        </p>
        <p className="fira-sans-light">
          Reading: Usually non-fiction. Currently reading <i className="fira-sans-light-italic">Architecture Patterns in Python</i>, and <i className="fira-sans-light-italic">Hands-On Data Structures and Algorithms with Python</i>.
        </p>
      </div>
    </div>
  );
}

export default App;
