import React from 'react';
import './Section.css';

const Section = ({ name }) => {
  const firstThreeChars = name.slice(0, 3);
  const remainingChars = name.slice(3);

  return (
    <h2 className="section">
      <span className="highlight">{firstThreeChars}</span>{remainingChars}
    </h2>
  )
}

export default Section;
