import React from "react";
import "./Accomplishment.css";

const Accomplishment = ({ data, school }) => {
  const unorderedList = data.map((accomplishment, index) => {
    const { heading, detail } = accomplishment;
    const schoolContent = school ? <strong>Relevant Coursework:</strong> : null;

    if (detail) {
      const detailContent = Array.isArray(detail) ? (
        detail.map((bullet, bulletIndex) => (
          <li key={bulletIndex} className="fira-sans-light">{bullet}</li>
        ))
      ) : (
        <li key="1" className="fira-sans-light">{detail}</li>
      )
  
      return (
        <li key={index} className="fira-sans-light">
          {schoolContent} {heading}
          <ul>
            {detailContent}
          </ul>
        </li>
      )
    } else {
      return (
        <li key={index} className="fira-sans-light">
          {schoolContent} {heading}
        </li>
      )
    };
  });

  return (
    <div className="accomplishment">
      <ul>
        {unorderedList}
      </ul>
    </div>
  );
}

export default Accomplishment;