import React from 'react';
import Accomplishment from '../common/Accomplishment/Accomplishment';
import Heading from '../common/Heading/Heading';
import './Project.css';


const Project = ({ subtitle, time, accomplishments }) => {
  return (
    <>
      <div className="flex-row-between-end title-and-date">
        <Heading text={subtitle} classes="fira-sans-medium" level={4} />
        <h4>{time}</h4>        
      </div>
      <Accomplishment data={accomplishments} />
    </>
  )
}

export default Project;