import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import './LinksSection.css';

const LinksSection = () => {
  return (
    <div className="links-section">
      <a
        href="https://github.com/spencerf2"
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        <FontAwesomeIcon icon={faGithub} className="icon" />GitHub
      </a>
      <span className="dot">∙</span>
      <a
        href="https://www.linkedin.com/in/sf2"
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        <FontAwesomeIcon icon={faLinkedin} className="icon" />LinkedIn
      </a>
      <span className="dot">∙</span>
      <a
        href="spencer_franklin_resume.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        <FontAwesomeIcon icon={faFilePdf} className="icon" />Resume PDF
      </a>
    </div>
  )
}

export default LinksSection;