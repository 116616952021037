const summary =
  "Managed the daily operations of the first dispensary in one of the most regulated US markets. Entered purchase orders, corrected cannabis laboratories' concentration percentage calculations, and created user friendly discount calculation guides.";

const accomplishments = [
  {
    heading:
      "Developed compliant PoS software that accounted for over $200,000+ in additional sales.",
    detail: "",
  },
];

export { summary, accomplishments };
