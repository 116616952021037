import React from 'react';
import './Summary.css';

const Summary = ({ text }) => {
  return (
    <div className="summary">
      <p className="fira-sans-light">{text}</p>
    </div>
  );
}

export default Summary;