import React from 'react';
import './Heading.css';

const Heading = ({ text, classes, level, linkText, linkURL }) => {
  const HeadingTag = `h${level}`;
  
  return (
    <HeadingTag className={`${classes}`}>
      {
        level === 3 ? (
          <>
            <strong>
              {text}
            </strong>
            { linkText && linkURL && (
              <small> [<a href={linkURL} target="_blank" rel="noreferrer noopener">{linkText}</a>]</small>
            )}
          </>
        ) : text
      }
    </HeadingTag>
  );
}

export default Heading;