import React from 'react';
import './Company.css';
import Accomplishment from '../common/Accomplishment/Accomplishment';
import TitleAndDate from '../common/TitleAndDate/TitleAndDate';
import SubtitleAndLocation from '../common/SubtitleAndLocation/SubtitleAndLocation';
import Summary from '../common/Summary/Summary';


const Company = ({ title, time, linkText, linkURL, subtitle, location, summary, accomplishments }) => {
  return (
    // TODO: Convert fragment to div and apply top spacing/styling with .company
    // TODO: Remove bottom spacing from any component that is the last child of Company
    <>
      <TitleAndDate title={title} time={time} linkText={linkText} linkURL={linkURL} />
      <SubtitleAndLocation subtitle={subtitle} location={location} />
      <Summary text={summary} />
      { accomplishments && <Accomplishment data={accomplishments} /> }
    </>
  )
}

export default Company;