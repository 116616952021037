const summary =
  "Led critical integrations on short timelines. Protected uninterrupted service and over $6M in quick collections.";

const accomplishments = [
  {
    heading: "Balance Integration",
    detail:
      "Implemented financial integration in 6 weeks, eliminating the need for Finance to manually coordinate customer collections, freeing them to focus on quicker invoicing, enabling rapid collection of $6M, and reducing Airhouse’s interest expense. Prevented company shutdown.",
  },
  {
    heading: "Circular Inventory Fix",
    detail:
      "Resolved nested circular logic in key inventory calculations, enhancing system reliability, eliminating over/under selling—a major customer pain point—and improving platform clarity for customers. Saved Customer Service 6 hours of support time per week and Engineering 3 hours.",
  },
  {
    heading: "Shopify Mandatory Upgrades",
    detail: [
      "Secured managerial support and drove the project to completion before the critical deadline, safeguarding operations for 99%+ of our business.",
      "Overhauled order ingestion and fulfillment logic to align with Shopify’s latest version.",
      "Enhanced customer messaging and user experience regarding order fulfillment.",
      "Engineered custom Django Middleware to log protected data access.",
      "Formulated a cutover strategy and collaboratively resolved a post-deployment production issue.",
    ],
  },
];

export { summary, accomplishments };
